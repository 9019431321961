import { useState, useEffect } from "react";
import sanityClient from "../../Client";
import imageUrlBuilder from "@sanity/image-url";
import { MDBJumbotron, MDBRow, MDBContainer, MDBMask, MDBBtn } from "mdbreact";
import { SmoothScrollTo } from "../../utils/functions";
import styled from "styled-components";

const builder = imageUrlBuilder(sanityClient);

const Jumbotron = styled(MDBJumbotron)`
    text-shadow: 2px 2px 4px black;
    height: 100vh;
    background-color: black;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    @media (hover: none) {
        background-attachment: scroll;
    }
`

const Title = styled.h1`
    font-size: 84px;
    color: black;
    @media only screen and (max-width: 640px) {
        font-size: 72px;
    }
    @media only screen and (max-width: 480px) {
        font-size: 48px;
    }
`;

export default function Intro({ className }) {
    const [imageUrl, setImageUrl] = useState(null);
    const [navHeight, setNavHeight] = useState(55);

    useEffect(() => {
        sanityClient.fetch(`*[_id == "landingImg"]{
            image
        }`)
            .then(data => {
                setImageUrl(builder.image(data[0].image).url());
            });

        setNavHeight(document.getElementById("navbar").clientHeight);
    }, []);

    return (
        <Jumbotron fluid style={{"backgroundImage": `url(${imageUrl})`}} className={`p-0 ${className}`}>
            <MDBMask overlay="black-light" className="h-100">
                <MDBContainer className="d-flex flex-column align-items-center justify-content-center h-100">
                    <MDBRow>
                        <Title className="white-text">Tokyo Grill</Title>
                    </MDBRow>
                    <MDBRow>
                        <h3 className="white-text">いらっしゃいませ</h3>
                    </MDBRow>
                    <MDBRow>
                        <MDBBtn
                            outline
                            color="white"
                            className="chownow-order-online"
                            href="https://ordering.chownow.com/order/31266/locations"
                            target="_blank"
                            style={{ fontSize: "20px", textShadow: "inherit" }}
                        >
                            Order Online
                        </MDBBtn>
                    </MDBRow>
                    <MDBRow>
                        <MDBBtn
                            outline
                            color="white"
                            onClick={() => SmoothScrollTo("menu", navHeight)}
                            style={{ fontSize: "20px", textShadow: "inherit" }}
                        >
                            See Our Menu
                        </MDBBtn>
                    </MDBRow>
                </MDBContainer>
            </MDBMask>
        </Jumbotron>
    )
}
