import { useState, useEffect } from "react";
import { MDBNavbar, MDBNavbarBrand, MDBNavbarToggler,
    MDBCollapse, MDBNavbarNav, MDBNavItem, MDBNavLink } from "mdbreact";
import { SmoothScrollTo } from "../../utils/functions";
import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "../../Client";
import styled from "styled-components";

const builder = imageUrlBuilder(sanityClient);

const Navbar = styled(MDBNavbar)`
  text-shadow: 2px 2px 4px black;
  background-color: rgba(0, 0, 0, 0.3);
`

const NavLink = styled(MDBNavLink)`
  font-size: 18px;
  margin-right: 1em;
`

export default function NavBar() {
    const [isOpen, setOpen] = useState(false);
    const [logoData, setLogoData] = useState({});
    const [navHeight, setNavHeight] = useState(55);

    useEffect(() => {
        sanityClient.fetch(`*[_id == "logo"]{
            image,
            caption
        }`)
            .then(data => {
                setLogoData({alt: data[0].caption, url: builder.image(data[0].image).url()});
            });

        setNavHeight(document.getElementById("navbar").clientHeight);
    }, []);

    const toggleCollapse = () => {
        setOpen(open => !open);
    }

    const onClick = (to) => {
      return e => {
        e.preventDefault();
        SmoothScrollTo(to, navHeight);
        const toggler = document.getElementById("navToggler");
        // Only toggle collapse if the navbar is set to toggle mode.
        if (toggler.offsetParent) {
          toggleCollapse();
        }
      }
    }

    return (
      <Navbar id="navbar" color="stylish-color-dark" dark expand="md" fixed="top" transparent scrolling scrollingNavbarOffset={500}>
        <MDBNavbarBrand href="/">
          <img src={logoData.url} alt={logoData.alt} style={{ height: "50px" }} />
        </MDBNavbarBrand>
        <MDBNavbarToggler id="navToggler" onClick={toggleCollapse} />
        <MDBCollapse id="navbarCollapse3" isOpen={isOpen} navbar>
          <MDBNavbarNav left>
            <MDBNavItem>
              <NavLink to="/" onClick={onClick("home")}>Home</NavLink>
            </MDBNavItem>
            <MDBNavItem>
              <NavLink to="/" onClick={onClick("about")}>About Us</NavLink>
            </MDBNavItem>
            <MDBNavItem>
              <NavLink to="/" onClick={onClick("contact")}>Contact Us</NavLink>
            </MDBNavItem>
            <MDBNavItem>
              <NavLink to="/" onClick={onClick("gallery")}>Gallery</NavLink>
            </MDBNavItem>
            <MDBNavItem>
              <NavLink to="/" onClick={onClick("menu")}>Menu</NavLink>
            </MDBNavItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </Navbar>
    );
}