import { useState, useEffect } from "react";
import { MDBContainer, MDBRow } from "mdbreact";
import MenuItem from "./MenuItem";
import sanityClient from "../../Client";
import { sortByOrder } from "../../utils/functions";

export default function MenuContainer({ menu }) {
    const [menuSections, setMenuSections] = useState([]);

    useEffect(() => {
        async function getItems() {
            let sections = await sanityClient.fetch(`*[_type == "menuSection" && references("${menu._id}")]`);
            sections = sections.sort(sortByOrder);

            sections = await Promise.all(sections.map(async (section) => {
                const items = await sanityClient.fetch(`*[_type == "menuItem" && references("${section._id}")]`);
                return {
                    ...section,
                    items
                }
            }));

            setMenuSections(sections);
        }

        getItems();
    }, [menu._id]);

    return (
        <MDBContainer className={`d-flex flex-column align-items-center stylish-text`}>
            <div className="mb-3 text-center">
                {menu.description !== "" && <h6>{menu.description}</h6>}
            </div>
            {
                menuSections.map((section, sIdx) => {
                    return (
                        <MDBContainer className="p-0" key={`s-${sIdx}`}>
                            <div className="mb-5 text-center">
                                <h4>{section.section}</h4>
                                {section.description && <h6>{section.description}</h6>}
                                <hr />
                            </div>
                            <MDBRow className="mb-3">
                                {
                                    section.items.map((item, iIdx) => 
                                        <MenuItem item={item} key={`i-${iIdx}`} />
                                    )
                                }
                            </MDBRow>
                        </MDBContainer>
                    );
                })
            } 
        </MDBContainer>
    );
}