import { useState, useEffect } from "react";
import { MDBContainer, MDBRow } from "mdbreact";
import sanityClient from "../../Client";
import MenuItem from "./MenuItem";
import { sortByOrder } from "../../utils/functions";

export default function PopularItems() {
    const [popularItems, setPopularItems] = useState([]);

    useEffect(() => {
        sanityClient.fetch(`*[_type == "popularSection"] {
            section,
            order,
            "popularItems": popularItems[]->
        }`)
            .then(data => {
                setPopularItems(data.sort(sortByOrder));
            });
    }, []);

    return (
        <MDBContainer className={`d-flex flex-column align-items-center`}>
            <div className="mb-5"></div>
            {
                popularItems.map((section, sIdx) => {
                    return (
                        <MDBContainer className="p-0" key={`s-${sIdx}`}>
                            <h4 className="mb-3 text-center">{section.section}</h4>
                            <hr />
                            <MDBRow className="mb-3 stylish-text">
                                {
                                    section.popularItems.map((item, iIdx) => 
                                        <MenuItem item={item} key={`i-${iIdx}`} />
                                    )
                                }
                            </MDBRow>
                        </MDBContainer>
                    );
                })
            } 
        </MDBContainer>
    );
}