import { useState, useEffect } from "react";
import { MDBContainer, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBBtn } from "mdbreact";
import PopularItems from "./PopularItems";
import MenuContainer from "./MenuContainer";
import sanityClient from "../../Client";
import { sortByOrder } from "../../utils/functions";

export default function Menu({ className }) {
    const [menus, setMenus] = useState([]);
    const [activeTab, setActiveTab] = useState(0);

    const toggleTab = tab => () => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    } 

    useEffect(() => {
        sanityClient.fetch(`*[_type == "menu"]`)
            .then(data => {
                setMenus(data.sort(sortByOrder));
            });
    }, []);

    return (
        <div className={className} id="menu">
            <MDBContainer>
                <h1 className="text-center">MENU</h1>
                <div className="text-center">
                    <MDBBtn
                        size="lg"
                        color="success"
                        className="chownow-order-online"
                        href="https://ordering.chownow.com/order/31266/locations"
                        target="_blank"
                    >
                        Order Online
                    </MDBBtn>
                </div>
                <MDBNav className="mt-5 d-flex justify-content-center" tabs>
                    {
                        [{ menu: "Popular" }, ...menus].map((menu, tIdx) => {
                            return (
                                <MDBNavItem
                                active={activeTab === tIdx}
                                onClick={toggleTab(tIdx)}
                                className="stylish-text custom-hover-tab ml-md-auto mr-md-auto ml-3 mr-3"
                                key={`mt-${tIdx}`}
                                >
                                    {menu.menu.toUpperCase()}
                                </MDBNavItem>
                            )
                        })
                    }
                </MDBNav>
                <hr />
                <h6 className="text-center">🌱 Vegetarian / 🍣 Raw</h6>
                <MDBTabContent activeItem={activeTab}>
                    <MDBTabPane className="pt-3" tabId={0}>
                        <PopularItems />
                    </MDBTabPane>
                    {
                        menus.map((menu, mIdx) =>
                            <MDBTabPane className="pt-3" tabId={mIdx + 1} key={`t-${mIdx}`}>
                                <MenuContainer menu={menu} key={`m-${mIdx}`} />
                            </MDBTabPane>
                        )
                    }
                </MDBTabContent>
            </MDBContainer>
        </div>
    );
}