import { useState, useEffect } from "react";
import { MDBContainer, MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem } from "mdbreact";
import sanityClient from "../../Client";
import imageUrlBuilder from "@sanity/image-url";
import useWindowDimensions from "../../hooks/useWindowDimensions";


const builder = imageUrlBuilder(sanityClient);


export default function Gallery({ className }) {
    const [gallery, setGallery] = useState([]);
    const { width } = useWindowDimensions();

    useEffect(() => {
        sanityClient.fetch(`*[_id == "gallery"]{
            pictures
        }`)
            .then(data => {
                let pictures = data[0].pictures;
                pictures = pictures.map(picture => {
                    return {
                        url: builder.image(picture.image).size(1920, 1080).fit("crop").crop("center").url(),
                        ...picture
                    }
                })

                setGallery(pictures);
            });
    }, []);


    return (
        <div id="gallery">
            <ConditionalWrapper
                condition={width > 960}
                wrapper={children =>
                    <MDBContainer
                        fluid
                        className={`p-5 ${className}`}
                        style={{
                            backgroundImage: "url(https://i.imgur.com/GnQswlg.jpg)",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundAttachment: "fixed"
                        }}
                    >
                        {children}
                    </MDBContainer>
                }>
                <MDBContainer
                size="lg"
                className={`p-0 ${width > 960 ? "" : className}`}
                >
                    <MDBCarousel
                    activeItem={1}
                    length = {gallery.length}
                    interval={3000}
                    slide
                    >
                        <MDBCarouselInner className="border boarder-white">
                            {
                                gallery.map((picture, idx) => {
                                    return (
                                        <MDBCarouselItem itemId={idx + 1} key={idx + 1}>
                                            <img
                                            className="d-block w-100"
                                            style={{
                                                objectFit: "cover"
                                            }}
                                            src={picture.url}
                                            alt={picture.alt}
                                            />
                                            <MDBCarouselCaption style={{ backgroundColor: "rgba(0, 0, 0, 0.3)"}}>
                                                <h3 className="h3-responsive">{picture.caption}</h3>
                                            </MDBCarouselCaption>
                                        </MDBCarouselItem>
                                    );
                                })
                            }
                        </MDBCarouselInner>
                    </MDBCarousel>
                </MDBContainer>
            </ConditionalWrapper>
        </div>
    )
}


const ConditionalWrapper = ({ condition, wrapper, children }) => {
    return condition ? wrapper(children) : <>{children}</>;
}