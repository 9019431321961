import React from "react";
import NavBar from "../navbar/NavBar";
import Intro from "./Intro";
import Bio from "./Bio";
import Contact from "./Contact";
import Gallery from "./Gallery";
import Menu from "../menu/Menu";

export default function Homepage() {
    return (
        <>
            <header id="home">
                <NavBar />
            </header>
            <main>
                <Intro />
                <Bio className="mb-5" />
                <Contact className="mb-5" />
                <Gallery className="mb-5" />
                <Menu className="mb-5" />
            </main>
        </>
    );
}
