import { MDBCol } from "mdbreact";

export default function MenuItem({ item }) {
    return (
        <MDBCol lg="4" md="6">
            <div className="d-flex justify-content-between">
                <p className="mb-0 font-weight-bold">{item.name}</p>
                <p className="mb-0">{item.vegetarian && "🌱"} {item.raw && "🍣"}</p>
            </div>
            <p className="mt-0 mb-0"><b>{item.price ? formatPrice(item.price) : item.altprice}</b></p>
            <p className="mt-0">{item.description}</p>
        </MDBCol>
    );
}

function formatPrice(price) {
    price = "$" + price;
    const pIdx = price.indexOf('.');
    if (pIdx < 0) {
        price += ".00"
    } else {
        const decimalPlaces = price.substring(pIdx + 1).length

        for (let i = 0; i < 2 - decimalPlaces; i++) {
            price += "0";
        }
    }

    return price;
}