export function sortByOrder(a, b) {
    return a.order === b.order ? 0 : a.order > b.order ? 1 : -1;
}

export function SmoothScrollTo(to, offset) {
    const element = document.getElementById(to);
    if (element) {
        let elementPosition = element.getBoundingClientRect().top;
        elementPosition += window.scrollY;
        const offsetPosition = elementPosition - offset;
        window.scrollTo({ top: offsetPosition, behavior: "smooth" });
    }
}
