import { useState, useEffect } from "react";
import {MDBContainer, MDBRow, MDBCol } from "mdbreact";
import sanityClient from "../../Client";
import styled from "styled-components";

const gMapUrl = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3081.558393905586!2d-87.41540638448043!3d39.4341094227333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x886d7ae65663a9fd%3A0xf51aa4cc4f8a186!2s2950%20S%203rd%20St%2C%20Terre%20Haute%2C%20IN%2047802!5e0!3m2!1sen!2sus!4v1609025147264!5m2!1sen!2sus" 
const Heading = styled.p`
    margin-bottom: 0;
    font-weight: bold;
    text-transform: uppercase;
`
const Indent = styled.p`
    padding-left: .5em;

    @media only screen and (max-width: 365px) {
        padding-left: 0;
    }
`

export default function Contact({ className }) {
    const [contactInfo, setContactInfo] = useState({});
    const [hoursInfo, setHoursInfo] = useState({});

    useEffect(() => {
        sanityClient.fetch(`*[_id == "contact"]`)
            .then(data => {
                setContactInfo(data[0]);
            });
            
        sanityClient.fetch(`*[_id == "hours"]`)
            .then(data => {
                setHoursInfo(data[0]);
            });
    }, []);

    return (
        <MDBContainer id="contact" className={className}>
            <MDBRow className="d-flex flex-wrap-reverse">
                <MDBCol className="d-flex flex-column justify-content-center" md="fluid" lg="6">
                    <Heading className="stylish-text">Phone</Heading>
                    <div className="stylish-text">
                        <Indent>
                            {contactInfo.pphone ? `Primary Phone: ${formatPhoneNumber(contactInfo.pphone)}` : null}
                            {contactInfo.sphone ?
                                <>
                                    <br />
                                    Secondary Phone: {formatPhoneNumber(contactInfo.sphone)}
                                </>
                                : null
                            }
                        </Indent>
                    </div>
                    <Heading className="stylish-text">Location</Heading>
                    <div className="stylish-text">
                        <Indent>
                            {contactInfo.address}
                            <br />
                            {formatAddress(contactInfo)}
                        </Indent>
                    </div>
                    <Heading className="stylish-text">Hours</Heading>
                    <div className="stylish-text">
                        <Indent>
                            {hoursInfo.hours ? 
                                hoursInfo.hours.map((time, idx) => {
                                    return <span key={idx}><b>{time.days}</b> : {time.open} - {time.close}<br /></span>;
                                }) : null
                            }
                            <br />
                            {hoursInfo.notes ?
                                hoursInfo.notes.split("\n").filter(note => note !== "").map((note, idx) => {
                                    return <span key={idx}>{note}<br /></span>;
                                }) : null
                            }
                        </Indent>
                    </div>
                </MDBCol>
                <MDBCol className="d-flex flex-column justify-content-center" md="fluid" lg="6">
                    <iframe src={gMapUrl} title="Map" frameBorder="0" style={{ border: 0, width: "100%", height: "400px" }} allowFullScreen="" aria-hidden="false"></iframe>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
}

function formatPhoneNumber(number) {
    let digits = number.replace(/\D/g, "");
    let areaCode = "";

    if (digits.length > 7) {
        areaCode = `(${digits.substring(0, 3)}) `;
        digits = digits.substring(3);
    }

    digits = `${digits.substring(0, 3)}-${digits.substring(3)}`;

    return areaCode + digits;
}

function formatAddress({city, state, zipcode}) {
    let arr = [city, state, zipcode];
    arr = arr.filter(s => s !== null && s !== "");
    return arr.join(", ");
}