import { Fragment } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Homepage from "./components/home/Homepage";

function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <Route component={Homepage} path="/" exact />
      </BrowserRouter>
    </Fragment>
  );
}

export default App;