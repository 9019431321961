import { useEffect, useState } from "react";
import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "../../Client";
import {MDBContainer, MDBRow, MDBCol } from "mdbreact";
import styled from "styled-components";

const builder = imageUrlBuilder(sanityClient);

const Title = styled.h1`
    font-weight: bold;
    font-size: 36px;

    @media only screen and (max-width: )
`

export default function Bio({ className }) {
    const [imageData, setImageData] = useState({});

    useEffect(() => {
        sanityClient.fetch(`*[_id == "about"]{
            image,
            alt
        }`)
            .then(data => {
                setImageData({alt: data.alt, url: builder.image(data[0].image).url()});
            });
    }, []);

    return (
        <MDBContainer id="about" className={className}>
            <MDBRow>
                <MDBCol md="fluid" lg="6">
                    <img src={imageData.url} alt={imageData.alt} style={{ width: "100%" }}/>
                </MDBCol>
                <MDBCol className="d-flex flex-column justify-content-center" md="fluid" lg="6">
                    <Title className="stylish-text">About Us</Title>
                    <div className="stylish-text">
                        <p>
                            Tokyo Grill was first opened in 2007 with the primary goal of bringing authentic Japanese
                            cuisine and the hibachi grill experience to Terre Haute. We strove for unparalleled food
                            quality and excellent customer service.
                        </p>
                        <p>
                            Years later, in 2016, Tokyo moved to a new location as we continued to find new ways to
                            improve the restaurant. We are proud to state that we have maintained the same objectives
                            since Tokyo's founding, and we intend to stand firm on our beliefs. Join us at Tokyo and
                            try out our sushi, hibachi, and full-service bar!
                        </p>
                    </div>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    )
}